<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm id="award_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="8">
                <CInput
                  label="Nombre:"
                  id="name"
                  name="name"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Tipo"
                  id="type"
                  name="type"
                  :value.sync="$v.form.type.$model"
                  :options="typeOptions"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Descripción:"
                  id="description"
                  name="description"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  :isValid="checkIfValid('description')"
                  placeholder=""
                  autocomplete="given-description"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image"
                    id="image"
                    label="Imagen del concurso:"
                    :lazy="false"
                    :value.sync="$v.form.image.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CInput
                  label="Precio:"
                  type="number"
                  id="price"
                  name="price"
                  :lazy="false"
                  :value.sync="$v.form.price.$model"
                  :isValid="checkIfValid('price')"
                  placeholder=""
                  autocomplete="given-price"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Disponibilidad:"
                  type="number"
                  id="stock"
                  name="stock"
                  :lazy="false"
                  :value.sync="$v.form.stock.$model"
                  :isValid="checkIfValid('stock')"
                  placeholder=""
                  autocomplete="given-stock"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Ciudad:"
                  type="text"
                  id="city"
                  name="city"
                  :lazy="false"
                  :value.sync="$v.form.city.$model"
                  placeholder=""
                  autocomplete="given-city"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric, minLength, helpers, requiredIf, requiredUnless } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone';
import ws from '../../services/challenges';
import store from '../../store'

export default {
  name: 'AwardModal',
  props: {

  },
  components: { 'v-date-picker': DatePicker },
  data () {
    return {
      showModal: false,
      title: "Nuevo Premio",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [
        {label: 'Puntos para Eventos', value: 'event_points'},
        {label: 'Puntos para Certificados', value: 'card_points'},
        {label: 'Puntos para Premios', value: 'gift_points'},
        {label: 'Puntos para Monedero', value: 'wallet_points'}
      ],
      link: '',
      item: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Premio';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          description: '',
          type: 'event_points',
          stock: '',
          price: '',
          image: null,
          city: ''
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Premio';
      this.isEdit = true;
      this.submitted = false;

      this.item = item;

      this.form = {
          id: item.id,
          name: item.name,
          description: item.description,
          type: item.type,
          stock: item.stock,
          price: item.price,
          city: item.city
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("award_form");
        const formData = new FormData(form);

        formData.append("id", this.form.id);

        if(this.form.id > 0){
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          description: '',
          type: 'event_points',
          stock: '',
          price: '',
          image: null,
          city: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      description: {
        required,
        minLength: minLength(3)
      },
      type: {
        required
      },
      stock: {
        required
      },
      price: {
        required
      },
      image:{

      },
      city: {
        
      },
    }
  },
}
</script>