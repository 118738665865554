<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions"></div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #type="{item}"><td class="w100 text-center">
          <span style="font-font-size: 14px;" v-html="item.type == 'event_points' ? 'Eventos' : (item.type == 'card_points' ? 'Certificados' : (item.type == 'gift_points' ? 'Premios' : item.type == 'wallet_points' ? 'Monedero' : '-'))"></span>
        </td></template>
        <template #award_name="{item}"><td class="w300 text-left">
          <span style="font-weight: bold; font-size: 14px;">{{item.name}}</span><br />
          <span style="font-style: italic; font-size: 12px;">{{item.description}}</span>
        </td></template>
        <template #supplier_name="{item}"><td class="w300 text-left">
        <span style="font-weight: bold; font-size: 14px;">{{item.supplier_name}}</span><br />
        <span style="font-style: italic; font-size: 12px;">{{item.user_email}}</span><br />
        <span v-if="item.user_whatsapp" style="font-style: italic;">{{item.user_whatsapp}}<br /></span>

        </td></template>
        <template #price="{item}"><td class="w100 text-center">{{item.price}}</td></template>
        <template #date="{item}"><td class="w150 text-center">{{item.date | date}}</td></template>
        <template #is_paid="{item}"><td class="w100 text-center">
          <span>
            <CButton v-if="item.is_paid" color="success" size="sm" @click="unpaidProcess(item.exchange_id)"><CIcon name="cil-check"/></CButton>
            <CButton v-if="!item.is_paid" color="danger" size="sm" @click="paidProcess(item.exchange_id)"><CIcon name="cil-ban"/></CButton>
          </span>
        </td></template>
      </CDataTable>
    </CCardBody>
    <AwardModal ref="awardModal" @store="storeProcess" @update="updateProcess"></AwardModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import AwardModal from './AwardModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/awards';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'AwardExchangesTable',
  components: { AwardModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'date', label: 'Fecha'},
          {key: 'supplier_name', label: 'Nombre'},
          {key: 'award_name', label: 'Premio'},
          {key: 'type', label: 'Tipo'},
          {key: 'price', label: 'Puntos'},
          {key: 'is_paid', label: '¿Entregado?'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      challenge_id: 0,
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  methods: {
    async paidProcess(id) {
      let response = await ws.paid({id: id});
      
      if(response.type == "success"){ 
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async unpaidProcess(id) {
      let response = await ws.unpaid({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
